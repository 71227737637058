export default class TaskStatuses {
  static NO_PRIORITY = "noPriority";

  static LOW_PRIORITY = "lowPriority";

  static HIGH_PRIORITY = "highPriority";

  static EXPIRED = "expired";

  static COMPLETED = "completed";
}
