import Css from "./style.module.scss";

import ActivityPageDesktop from "nlib/pages/ActivityPage";
import React from "react";

const ActivityPage = () => {
  return (
    <ActivityPageDesktop className={Css.activityPage} />
  );
};

export default React.memo(ActivityPage);
