import { useMemo } from "react";
import { useSelector } from "react-redux";
import moment from "moment";

import { getAllUsersData } from "selectors/organizations";
import { getSelectedBusinessUsersData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { getUserData } from "selectors/user";
import Constants from "const/Constants";
import TaskStatuses from "const/TaskStatuses";
import UserRoles from "const/UserRoles";
import Utils from "utils/Utils";

const { DATE_TEXT } = Constants.DATETIME_FORMATS;

const {
  NO_PRIORITY,
  LOW_PRIORITY,
  HIGH_PRIORITY,
  COMPLETED,
  EXPIRED
} = TaskStatuses;

const useTaskContent = (content = {}) => {
  const { completed, dueDate, createdAt, createdBy, targetUser } = content;

  const { uiTexts } = useSelector(getTextsData);

  const userData = useSelector(getUserData);

  const allUsersData = useSelector(getAllUsersData);

  const targetUserId = targetUser && (targetUser.id || targetUser);

  const taskCreatedByYou = createdBy && userData.id === createdBy.id;

  const selectedBusinessUsersData = useSelector(getSelectedBusinessUsersData);

  const createdByUserData = Utils.arrayFindById(selectedBusinessUsersData, (createdBy && createdBy.id) || createdBy);

  const taskCreatedByYourSide = createdByUserData
    && UserRoles.getRoleType(userData.role) === UserRoles.getRoleType(createdByUserData.role);

  const dueDateMoment = useMemo(() => dueDate ? moment(dueDate) : null, [dueDate]);

  const taskForYou = targetUserId ? targetUserId === userData.id : !taskCreatedByYou;

  const { fullName: taskForName } = Utils.arrayFindById(allUsersData, targetUserId, {});

  const creationDate = useMemo(() => {
    return moment.duration(moment(createdAt).diff(moment())).humanize(true);
  }, [createdAt]);

  const status = useMemo(() => {
    if (completed) return COMPLETED;
    if (!dueDateMoment) return NO_PRIORITY;

    const today = moment.utc(Utils.formatNoTimeZoneDate(new Date(), false));

    if (dueDateMoment.isSameOrBefore(today.startOf("day"))) {
      return EXPIRED;
    } else if (dueDateMoment.isAfter(today.add(1, "day").endOf("day"))) {
      return LOW_PRIORITY;
    }

    return HIGH_PRIORITY;
  }, [completed, dueDateMoment]);

  const dueDateContent = useMemo(() => {
    switch (status) {
      case COMPLETED:
        return uiTexts.completed.toUpperCase();
      case NO_PRIORITY:
        return null;
      default:
        return dueDateMoment.format(DATE_TEXT);
    }
  }, [dueDateMoment, status, uiTexts]);

  const createdByContent = createdBy ? (taskCreatedByYou ? uiTexts.you : createdBy.fullName) : uiTexts.unknown;

  return {
    status,
    taskForYou,
    taskForName,
    creationDate,
    dueDateContent,
    taskCreatedByYou,
    taskCreatedByYourSide,
    createdByContent
  };
};

export default useTaskContent;
